import React from 'react';
import pumpfun from './assets/pumpfun.png';
import twitter from './assets/twitter.png';
import logo1 from './assets/1.jpeg';
import logo2 from './assets/2.jpeg';
import logo3 from './assets/3.jpeg';
import telegram from './assets/telegram.png';
import dexscreener from './assets/dexscreener.png';
import dexscreenerwhite from './assets/dexscreenerwhite.png';

const handleClick = () => {
    navigator.clipboard.writeText("Temp");
};

const LandingPage = () => {
  return (
    <div className="bg-[#fcf9f7] min-h-screen">
        <div
        className="min-h-screen flex flex-col justify-center"
        >
            <div className="pt-6 px-32 flex justify-between items-center">
                <div className="flex items-center justify-center">
                    <a href="/">
                        <img src={logo2} className="h-14 w-14 rounded rounded-full"></img>
                    </a>
                    <p className="ml-4 font-bold text-2xl">$JUMPY</p>
                </div>
                <div className="text-[#111111]">
                    <a href="#" className="border rounded rounded-xl bg-[#111111] text-white text-xl md:px-6 md:py-4 px-2 py-1 inline-flex items-center hover:bg-[#b0b0b0]">
                        <img src={pumpfun} className="w-6 h-6"></img>
                        <p className="ml-2 font-semibold">Buy $JUMPY</p>
                    </a>
                </div>
            </div>
            <main className="flex-1 flex flex-col items-center">
                <div className="flex justify-center mt-10">
                    <div className="grid grid-cols-3 gap-20">
                        <div className="flex items-end justify-center">
                            <img src={logo1} className="w-3/5 h-2/3 rounded rounded-xl"></img>
                        </div>
                        <div className="flex justify-center">
                            <img src={logo2} className="w-3/5 rounded rounded-xl"></img>
                        </div>
                        <div className="flex items-end justify-center">
                            <img src={logo3} className="w-3/5 h-2/3 rounded rounded-xl"></img>
                        </div>
                    </div>
                </div>
                <p className="text-6xl cursor-pointer pt-8 pb-4 text-[#111111] hover:text-[#b0b0b0] font-bold" onClick={handleClick}>Click me to copy CA!</p>
                <p className="text-3xl text-[#111111]">CA: temp</p> 
                
            </main>
            <footer className="w-full mb-4 font-semibold text-center text-[#111111]">
                <div className="flex justify-center mb-4">
                    <div className="flex-1 flex justify-center">
                        <a href="https://t.me/jumpy_onsol" className="text-4xl hover:text-[#343434] text-[#111111] inline-flex items-center rounded rounded-xl p-4">
                            <p className="mr-2">Telegram</p>
                            <img src={telegram} className="h-10"></img>
                        </a>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <a href="https://x.com/Jumpy_onsol" className="text-4xl hover:text-[#343434] text-[#111111] inline-flex items-center rounded rounded-xl p-4">
                            <p className="mr-2">Twitter</p>
                            <img src={twitter} className="h-10"></img>
                        </a>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <a href="#" className="text-4xl hover:text-[#343434] text-[#111111] inline-flex items-center rounded rounded-xl p-4">
                            <p className="mr-2">Dexscreener</p>
                            <img src={dexscreener} className="h-10"></img>
                        </a>
                    </div>
                </div>
                &copy; 2024 by $JUMPY. All rights reserved.
            </footer>
        </div>
    </div>
  );
};

export default LandingPage;